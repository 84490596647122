<template>
  <div class="product-box">
    <div class="product-img d-flex">
      <img
        v-if="activity.photo"
        class="object-fit-img"
        height="130"
        width="180"
        :src="activity.id ? $filePath(activity.photo.contentUrl) : localUrlLink(activity.photo)"
      >
      <img
        v-else
        class="pointer"
        src="@/assets/icons/playground/new/step-1/upload-image.svg"
        height="130"
        width="180"
        alt
        @click="uploadPhoto"
      >
      <div v-if="activity.photo" class="product-hover">
        <ul>
          <li class="pointer" @click="uploadPhoto">
            <button class="btn" type="button">
              <i class="icon-upload" />
            </button>
          </li>
          <li class="pointer" @click="deletePhoto">
            <button class="btn" type="button">
              <i class="icon-trash" />
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { fromIdToIriReference } from "@/utils/form";
import { URL_ACTIVITIES } from "@api/services/activities/activities.api";
import { _delete } from "@api/services/httpService";

export default {
  props: {
    activity: {
      type: Object,
      default: null,
    },
  },
  data: () => ({ isBusy: false }),
  computed: {
    isEditing () {
      return this.activity.id !== null;
    },
  },
  methods: {
    localUrlLink (photo) {
      return URL.createObjectURL(photo);
    },
    async uploadPhoto () {
      if (this.isBusy) {
        return;
      }
      this.isBusy = true;
      if (this.isEditing) {
        await this.deletePhoto();
        this.$upload(this.uploaded, {
          entry: "activity",
          url: fromIdToIriReference(URL_ACTIVITIES, this.activity.id),
          target: "postActivityPhoto",
        });
      } else {
        this.$upload(this.uploaded, {
          entry: "activity",
          url: null,
          target: "",
        });
      }
      this.isBusy = false;
    },
    async deletePhoto () {
      if (this.activity.photo) {
        await _delete(this.activity.photo["@id"]);
        this.$emit("change", null);
      }
    },
    uploaded (file) {
      this.$emit("change", file);
    },
  },
};
</script>

<style scoped lang="scss">
.product-box {
  .product-img {
    img {
      border-radius: 10px;
    }
    .product-hover {
      height: 130px;
      width: 180px;
    }
  }
}
</style>