import { hydrate } from "@/utils/form";
export const MIN_LEVEL = 'min';
export const MAX_LEVEL = 'max';

export default class ActivityLevel {
  id = null;
  ranks = [];
  label = null;
  activity = null;
  identifier = null;
  description = null;
  shortDescription = null;

  constructor(object, options) {
    if ("undefined" !== typeof options) {
      if(options.serialize) {
        this.serialize(object);
        this.format(object);
      }else if(options.deserialize) {
        this.deserialize(object);
      }
    }
  }

  serialize (object) {
    hydrate(this, object);
  }

  deserialize (object) {
    hydrate(this, object);
  }

  format(object) {
    this.levelIdentifier = object.identifier;
    this.levelsLabel = object.label;
    this.levelDescription = object.description;
    this.levelShortDescription = object.shortDescription;
  }

}
