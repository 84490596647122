<template>
  <div>
    <d-body-top-bar :title="$t('admin.activity.title')">
      <div>
        <label class="inactive-tree">
          {{ $t('admin.activity.side-bar-title') }}
        </label>
      </div>
    </d-body-top-bar>

    <div
      class="container-fluid"
    >
      <div class="d-flex justify-content-center align-items-center spinner-busy" v-if="isBusy">
        <b-spinner
          variant="blue-light-spinner"
          type="grow"></b-spinner>
      </div>
      <template v-else>
        <b-col cols="12">
          <b-card>
            <b-row class="row-style" no-gutters>
              <b-col class="mt-2 ml-3" />
              <b-col class="mt-3 text-right mr-2" cols="4">
                <d-button
                  text="admin.activity.create"
                  class="ml-2 btn d-btn d-btn-primary"
                  icon="fa fa-plus mr-1"
                  @on:button-click="createOrUpdateActivity"
                />
              </b-col>
              <b-col class="mt-3 mr-3">
                <div class="has-search">
                  <span class="fa fa-search form-control-feedback"></span>
                  <input
                    type="text"
                    class="form-control"
                    v-model="filter"
                    @keyup="manualFilter"
                    :placeholder="$t('components.doinsport.table.search')"
                  >
                </div>
              </b-col>
            </b-row>
            <div class="d-flex flex-wrap justify-content-end">
              <template v-if="activities.length > 1">
                <template v-for="(activity, i) of activities">
                  <b-col
                    :key="'photo' + i"
                    :cols="cols"
                  >
                  <span
                    class="d-flex justify-content-center activate-desactivate mb-1 mt-4"
                  >
                    {{ activity.name }}
                  </span>
                    <div class="product-box">
                      <div class="product-img">
                        <div class="container-over-img">
                          <img
                            width="100%"
                            class="picture-card"
                            :src="activity.photo ? $filePath(activity.photo.contentUrl) : require('@/assets/images/playground/ban.svg')"
                          />
                        </div>
                        <div class="product-hover">
                          <ul>
                            <li
                              class="pointer"
                              @click="createOrUpdateActivity(activity)"
                            >
                              <button
                                class="btn"
                                type="button"
                              >
                                <i class="icon-pencil"></i>
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </b-col>
                </template>
              </template>
            </div>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="center"
              pills
              class="mb-4 mt-4"
            ></b-pagination>
          </b-card>
        </b-col>
      </template>

    </div>
    <create-or-update-activity
      v-if="activity"
      modal-id="activity-modal-id"
      :activity="activity"
      :display="displayActivityModal"
      @on:activity-photo:updated="onActivityPhotoUpdated"
      @on:activity:created-or-updated="onActivityCreatedOrUpdated"
      @on:level:created-or-updated="loadActivities"
      @on:delete-level="loadActivities"
    />
  </div>
</template>

<script>
import {deleteActivity, getActivities} from "@api/services/activities/activities.api";
import Activity from "@/classes/Activity";
import CreateOrUpdateActivity from "@custom/activities/CreateOrUpdateActivity";
import {fromIdToIriReference} from "@/utils/form";

export default {
  name: "Index",
  components: {CreateOrUpdateActivity},
  data: () => ({
    activities: [],
    activity: null,
    displayActivityModal: false,
    displayDeleteModal: true,
    hideDeleteModal: false,
    isBusy: false,
    currentPage: 1,
    perPage: 12,
    totalRows: 0,
    filter: '',
    timer: null
  }),
  watch: {
    currentPage: function () {
      this.loadActivities();
    },
  },
  computed: {
    cols() {
      const width = this.$store.getters["layout/getInnerWidth"];

      if (width < 1180 && width > 828) {
        return 4;
      } else if (width < 828 && width >= 630) {
        return 6
      } else if (width < 630) {
        return 12;
      } else {
        return 3;
      }
    }
  },
  methods: {
    onActivityPhotoUpdated(scope) {
      if (scope.activity.id) {
        const findActivityToUpdate = this.activities.find(el => el.id === scope.activity.id);
        findActivityToUpdate.photo = scope.photo;
      }
    },
    onCancel () {
      this.hideDeleteModal = !this.hideDeleteModal;
    },
    search() {
      this.loadActivities();
    },
    loadActivities() {
      this.activities = [];
      getActivities(this.perPage, this.currentPage, this.filter)
        .then((response) => {
          this.totalRows = response.data['hydra:totalItems'];

          response.data['hydra:member'].forEach((activity) => {
            const act = new Activity(activity, {serialize: true});
            this.activities.push(act);
          })
        })
        .finally(() => this.isBusy = false);
    },

    onActivityCreatedOrUpdated() {
      this.activity = null;
      this.loadActivities();
    },
    createOrUpdateActivity(activity = null) {
      this.activity = activity ? new Activity(activity, {serialize: true}) : new Activity();

      this.$nextTick(
        () => {
          setTimeout(() => {
            this.displayActivityModal = !this.displayActivityModal;
          }, 50);
        })
      ;
    },
    manualFilter() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.search();
      }, 400);
    },
  },
  mounted() {
    this.loadActivities()
  }
}
</script>

<style scoped lang="scss">
.upload-photo-size .fa {
  font-size: 18px
}

.pictures-container .card {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 20px #C4C8D066;
  border-radius: 8px;
  opacity: 1;
}

.pictures-container .card .card-body {
  padding-top: 12px;
  padding-left: 35px;
  padding-right: 25px;
  padding-bottom: 12px;
  background-color: transparent;
}

.picture-card {
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 8px;
  min-height: 300px;
  max-height: 300px;
  object-fit: cover;
}

.container-over-img {
  position: relative;
}

.container-over-img img {
  display: block;
}

.container-over-img .container-over {
  position: absolute;
  top: 11px;
  right: 14px;
  z-index: 9999;
}

.container-over {
  padding: 5px 9px;
  background-color: #FDFDFD;
  border-radius: 4px;
}

.fa-star-inactive {
  color: #BAB9B9;
  font-size: 20px;
  margin-top: 3px;
}

.fa-star-active {
  color: #FED105;
  font-size: 20px;
  margin-top: 3px;
}

.activate-desactivate {
  font: normal normal normal 15px Avenir;
  font-weight: bold;
  color: #0B2772;
}
</style>
