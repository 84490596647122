<template>
  <div>
    <div class="text-right mr-2">
      <d-button
        text="admin.activity.levels.tab.add-level"
        class="btn d-btn d-btn-primary"
        :class="activity.id ? '' : 'disabled'"
        icon="fa fa-plus mr-1"
        @on:button-click="openLevelModal"
      />
    </div>
    <d-table
      id="levelsId"
      sticky-header="540px"
      :tablefields="fields"
      :items="activity.levels"
      :per-page="itemsPerPage"
      :totalRows="totalRows"
      :is-busy="isBusy"
      :current-page="currentPage"
      :show-pagination="false"
      @on:data-table-btn:edit="editLevel"
      @on:data-table-btn:delete="deleteLevel"
    />
    <create-or-update-level
      v-if="level"
      :level="level"
      :activity="activity"
      :hide="hideLevelModal"
      modal-id="level-modal-id"
      :display="displayLevelModal"
      @on:level:created-or-updated="$emit('on:level:created-or-updated')"
    />
  </div>
</template>

<script>

import CreateOrUpdateLevel from "@custom/activities/level/CreateOrUpdateLevel";
import ActivityLevel from "@/classes/ActivityLevel";
import {deleteLevel} from "@api/services/activities/level/level.api";

export default {
  components: {CreateOrUpdateLevel},
  data: () => ({
    isBusy: false,
    itemsPerPage: 500,
    totalRows: 1,
    currentPage: 1,
    level: null,
    displayLevelModal: false,
    hideLevelModal: false,
    lastIdentifier: 1
  }),
  props: {
    activity: {
      type: Object,
      default: null
    }
  },
  computed: {
    alignCellsToLeft() {
      return this.$store.getters['layout/getInnerWidth'] >= 991 ? 'text-center' : 'text-left';
    },
    fields() {
      return [
        {
          key: "levelIdentifier",
          label: this.$t('admin.activity.levels.tab.identifier'),
          sortable: false,
        },
        {
          key: "levelsLabel",
          label: this.$t('admin.activity.levels.tab.label'),
          class: this.alignCellsToLeft,
          sortable: false
        },
        {
          key: "levelDescription",
          label: this.$t('admin.activity.levels.tab.description'),
          class: this.alignCellsToLeft,
          sortable: false
        },
        {
          key: "levelShortDescription",
          label: this.$t('admin.activity.levels.tab.short-description'),
          class: this.alignCellsToLeft,
          sortable: false
        },
        {
          key: "actions", label:  this.$t("general.actions.actions"),
          class: this.alignCellsToLeft,
          sortable: false
        },
      ];
    },
  },
  methods: {
    editLevel(scope) {
      this.openLevelModal(scope);
    },
    deleteLevel(scope) {
      deleteLevel(scope.id)
        .then(() => {
          this.$emit("on:delete-level");
        })
    },
    openLevelModal(level = null) {
      if(this.activity.id) {
        this.level = level.id ? new ActivityLevel(level, {serialize: true}) : new ActivityLevel();

        this.$nextTick(
          () => {
            setTimeout(() => {
              this.displayLevelModal = !this.displayLevelModal;
            }, 50);
          })
        ;
      }
    }
  }
}
</script>

<style scoped lang="scss">
.bold-title {
  text-align: left;
  font: normal normal 900 17px Avenir;
  letter-spacing: 0.63px;
  color: #333333;
  opacity: 1;
}

/deep/ ::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

/deep/ ::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

</style>
