import { hydrate } from "@/utils/form";

export default class Activity {
  id = null;
  name = null;
  types = ["leisure", "sport", "lesson", "formula"];
  highlighted = false;
  colors = null;
  icon = null;
  photo = null;
  levels = [];
  scoreType = null;

  constructor (object, options = {}) {
    if(options.serialize) {
      this.serialize(object);
    }
  }

  serialize (object) {
    hydrate(this, object);
  }

}