<template>
  <b-modal
    v-if="level"
    :id="modalId"
    body-bg-variant="gray-lighten"
    size="sm"
    hide-header
    hide-footer
    hide-header-close
    header-class="my-second-class"
  >
    <b-row>
      <b-col
        align="middle"
        class="modal-title-class"
      >
        {{ $t(`admin.activity.levels.${mode}`) }}
      </b-col>
    </b-row>
    <div class="border-bottom-grey mt-2"/>
    <validation-observer
      ref="observerLevel"
      slim
    >
      <b-row
        class="mt-2"
      >
        <b-col cols="12" class="mb-3">
          <create-or-update-level-form
            :level="level"
            :validation="validation"
          />
        </b-col>
        <div>
          <b-col
            cols="12"
            align="middle"
          >
            <d-button
              text="general.actions.validate"
              class="d-btn btn d-btn-primary"
              @on:button-click="onValidate"
            />
          </b-col>
        </div>
      </b-row>
    </validation-observer>
  </b-modal>
</template>

<script>

import CreateOrUpdateLevelForm from "@form/CreateOrUpdateLevelForm";
import {postLevel, putLevel} from "@api/services/activities/level/level.api";
import {URL_ACTIVITIES} from "@api/services/activities/activities.api";
import {DANGER} from "@plugins/flash";
import {formatMessage} from "@/utils/flashError";
import ActivityLevel from "@/classes/ActivityLevel";

export default {
  name: "CreateOrUpdateLevel",
  components: {CreateOrUpdateLevelForm},
  data: () => ({
    validation: require('@validation/entities/ActivityLevel.json'),
    isBusy: false
  }),
  props: {
    activity: {
      type: Object,
      default: null,
    },
    modalId: {
      type: String,
      default: 'levelModalId'
    },
    display: {
      type: Boolean,
      default: false,
    },
    hide: {
      type: Boolean,
      default: false,
    },
    level: {
      type: Object,
      default: null
    }
  },
  computed: {
    mode() {
      return this.level.id ? 'update' : 'create';
    },
  },
  watch: {
    display: function () {
      this.$bvModal.show(this.modalId);
    },
    hide: function () {
      this.level = null;
      this.$bvModal.hide(this.modalId);
    },
  },
  methods: {
    hideAndReload() {
      this.$bvModal.hide(this.modalId);
      this.$emit('on:level:created-or-updated');
    },
    onValidate() {
      try {
        this.$refs.observerLevel.validate()
          .then((valid) => {
            if(valid) {

              if(!this.isBusy) {
                const serializedActivity = new ActivityLevel(this.level, {deserialize: true});
                serializedActivity.activity = '/' + URL_ACTIVITIES + '/' + this.activity.id;
                this.isBusy = true;

                this.createOrUpdateLevel(serializedActivity, this.level.id)
                  .then(() => {
                    this.hideAndReload();
                  })
                  .finally(() => {
                    this.isBusy = false;
                  })
                  .catch((error) => {
                    this.$flash(null, formatMessage(error), 5000, DANGER);
                  })
              }
            }
          })
      } catch (e) {}
    },
    createOrUpdateLevel(level, id) {
      return this.level.id ? putLevel(level, id) : postLevel(level);
    },
  }
}
</script>

<style lang="scss" scoped>
@import "@lazy/_modal.scss";
</style>
