import {clubId, _get, _post, _put, _delete} from "@api/services/httpService";

export const URL_ACTIVITIES = 'activities';
export const ACTIVITIES_PHOTO_URL = URL_ACTIVITIES+'/photos';

export const getActivities = (itemsPerPage, page, search) =>{
  let url = URL_ACTIVITIES  +
      '?itemsPerPage=' + itemsPerPage +
    '&page=' + page

  if (search !== '') {
    url += '&name=' + search;
  }

  return _get(url)
};

export const getLevelsActivities = (id) => _get(
  URL + '/' + id + '/levels'
);

export const postActivity = (activity) => _post(URL_ACTIVITIES, activity);

export const putActivity = (activity) => _put(URL_ACTIVITIES + '/' + activity.id, activity);

export const deleteActivity = (id) => _delete(URL_ACTIVITIES + '/' + id);

export const postActivityPhoto = (formData) => _post(ACTIVITIES_PHOTO_URL,
  formData, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  })
;