<template>
  <b-row>
    <b-col>
      <b-row
        class="mt-1"
        no-gutters
      >
        <b-col cols="12">
          <label class="col-form-label-sm field-name-class mb-0">
            {{ $t(`admin.activity.levels.tab.identifier-level`) }}
          </label>
        </b-col>
        <b-col cols="12">
          <d-number-field
            v-model.number="level.identifier"
            type="number"
            step="1"
            :min="1"
            :max="100"
            size="sm"
            :rules="validation.identifier"
            error-text="validation.input-number-identifier"
            class-name="custom-input custom-left-borders"
          />
        </b-col>
      </b-row>
      <b-row
        class="mt-1"
        no-gutters
      >
        <b-col cols="12">
          <label class="col-form-label-sm field-name-class mb-0">
            {{ $t(`admin.activity.levels.tab.label`) }}
          </label>
        </b-col>
        <b-col cols="12">
          <d-text-field
            v-model.trim="level.label"
            :rules="validation.text"
            size="sm"
            error-text="validation.text-input-three-characters"
          />
        </b-col>
      </b-row>
      <b-row
        class="mt-1"
        no-gutters
      >
        <b-col cols="12">
          <label class="col-form-label-sm field-name-class mb-0">
            {{ $t(`admin.activity.levels.tab.description`) }}
          </label>
        </b-col>
        <b-col cols="12">
          <d-text-field
            v-model.trim="level.description"
            :rules="validation.text-not-required"
            size="sm"
            error-text="validation.text-input-three-characters"
          />
        </b-col>
      </b-row>
      <b-row
        class="mt-1"
        no-gutters
      >
        <b-col cols="12">
          <label class="col-form-label-sm field-name-class mb-0">
            {{ $t(`admin.activity.levels.tab.short-description`) }}
          </label>
        </b-col>
        <b-col cols="12">
          <d-text-field
            v-model.trim="level.shortDescription"
            :rules="validation.text-not-required"
            size="sm"
            error-text="validation.text-input-three-characters"
          />
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>

export default {
  props: {
    level: {
      type: Object,
      default: null,
    },
    validation: {
      type: Object,
      default: null
    }
  },
}
</script>
<style scoped lang="scss">
@import "@lazy/level-form/level-form.scss";
</style>
