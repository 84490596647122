<template>
  <div>
    <label class="col-form-label-sm field-name-class mb-0">
      <span>
        {{ $t(`match.score.type.label`) }}
      </span>
      <span class="description">
        Le type unique permet de définir un score classique (comme le football), le type de sets permet de définir un score avec sets (comme le tennis).
      </span>
    </label>
    <b-form-select
      :value="value"
      :options="options"
      @input="$emit('input', $event)"
    />
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: null,
    },
  },
  data () {
    return {
      options: [{
        value: null,
        text: this.$t("general.actions.undefined"),
      }, {
        value: "unique",
        text: this.$t("match.score.type.unique"),
      }, {
        value: "multiple",
        text: this.$t("match.score.type.sets"),
      }],
    };
  },
};
</script>

<style lang="scss" scoped>
.description {
  display: block;
  font-size: 10px;
  color: #52575D;
  padding-left: 2px;
}
</style>