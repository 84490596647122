import {clubId, _get, _post, _put, _delete} from "@api/services/httpService";
import {URL_ACTIVITIES} from "@api/services/activities/activities.api";

export const URL_LEVELS = 'levels';
export const URL_LEVELS_ACTIVITIES = URL_ACTIVITIES + '/' + URL_LEVELS;

export const getLevel = (id) => _get(
  URL_ACTIVITIES + '/' + id + '/' + URL_LEVELS
);

export const postLevel = (level) => _post(URL_LEVELS_ACTIVITIES, level);

export const putLevel = (level, id) => _put(URL_LEVELS_ACTIVITIES + '/' + id, level);

export const deleteLevel = (id) => _delete(URL_LEVELS_ACTIVITIES + '/' + id)