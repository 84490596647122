<template>
  <b-modal
    :id="modalId"
    body-bg-variant="gray-lighten"
    size="lg"
    hide-header
    hide-footer
    hide-header-close
    header-class="my-second-class"
  >
    <b-row>
      <b-col
        align="middle"
        class="modal-title-class"
      >
        {{ $t(`admin.activity.${mode}`) }}
      </b-col>
    </b-row>
    <div class="border-bottom-grey mt-2"/>
    <validation-observer
      ref="observerActivity"
      slim
    >
      <b-row
        class="mt-2"
      >
        <b-col cols="12" class="mb-3">
          <create-or-update-activity-form
            :activity="activity"
            :validation="validation"
            @on:activity-photo:updated="$emit('on:activity-photo:updated', $event)"
            @on:level:created-or-updated="getLevelActivity(activity.id)"
            @on:delete-level="getLevelActivity(activity.id)"
          />
        </b-col>
        <b-col
          align="right"
        >
          <d-button
            text="general.actions.validate"
            class="d-btn btn d-btn-primary"
            @on:button-click="onValidate"
          />
        </b-col>
      </b-row>
    </validation-observer>
  </b-modal>
</template>
<script>

import Activity from "@/classes/Activity";
import CreateOrUpdateActivityForm from "@form/CreateOrUpdateActivityForm";
import {postActivity, putActivity} from "@api/services/activities/activities.api";
import ActivityLevel from "@/classes/ActivityLevel";
import {getLevel} from "@api/services/activities/level/level.api";

export default {
  components: {CreateOrUpdateActivityForm},
  data: () => ({
    isSaving: false,
    validation: require('@validation/entities/Default.json'),
    isBusy: false
  }),
  props: {
    modalId: {
      type: String,
      default: 'captureModalId'
    },
    display: {
      type: Boolean,
      default: false,
    },
    activity: {
      type: Object,
      default: null
    },
  },
  watch: {
    display: function () {
      if(this.activity.id) {
        this.getLevelActivity(this.activity.id)
      }
      this.$bvModal.show(this.modalId);
    },
    hide: function() {
      this.$bvModal.hide(this.modalId);
    }
  },
  computed: {
    mode() {
      return this.activity.id ? 'update' : 'create';
    }
  },
  methods: {
    hideAndReload() {
      this.activity = null;
      this.$bvModal.hide(this.modalId);
      this.$emit('on:activity:created-or-updated');
    },
    getLevelActivity(activity) {
      this.activity.levels = [];

      getLevel(activity)
        .then((response) => {
          response.data['hydra:member'].forEach((level) => {
            const actLevel = new ActivityLevel(level, {serialize: true});
            actLevel.actions = ['edit', 'delete'];
            this.activity.levels.push(actLevel);
          })
        })
    },
    onValidate() {
      try {
        this.$refs.observerActivity.validate()
          .then((valid) => {
            if (valid) {

              if(!this.isBusy) {
                const activity = new Activity(this.activity, {serialize: true});
                delete activity.photo;
                this.isBusy = true;

                this.createOrUpdateActivity(activity)
                  .then((response) => {
                    if (this.activity.id === null) {
                      if (this.activity.photo) {
                        const payload = {
                          entry: 'activity',
                          url: response.data['@id'],
                          target: 'postActivityPhoto',
                        };
                        this.$uploadFile(this.activity.photo, payload)
                          .then(() => {
                            this.hideAndReload();
                          })
                        ;
                      } else {
                        this.hideAndReload();
                      }
                    } else {
                      this.hideAndReload();
                    }
                  })
                  .finally(() => {
                    this.isBusy = true;
                  })
                ;
              }

            }
          })
        ;
      } catch (e) {}
    },

    createOrUpdateActivity(activity) {
      return this.activity.id ? putActivity(activity) : postActivity(activity);
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@lazy/_modal.scss";
</style>
